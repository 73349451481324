.home {
  z-index: 10;
  .bt {
    height: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    margin-bottom: 30px;
    flex-direction: column;
  }
  &__header {
    width: 190px;
    margin: 40px auto;
    color: #292929;
    font-family: "Trenda";
    font-size: 25px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 25px;
    text-align: center;
    position: relative;
    &::after {
      @include pseudo-class;
      right:-99%;
      bottom: 8px;
      width: 100%;
      height: 2px;
      background-color: #292929;
    }
    &::before {
      @include pseudo-class;
      left:-99%;
      top: 14px;
      width: 100%;
      height: 2px;
      background-color: #292929;
    }
  }
  .audioguide{
    background-color: $orange;

  }
  .audiodescription{
    background-color: $maroon;
  }
}
