$white: #f5eee9;
$black: #000;
$primary: #de5600 ;
$secondary:  #454444;
$orange: #b54b12;
$maroon: #741e00;
$terciary: #707070;
$quatrequatregris: #242424;
$width: 100%;
$height: auto;

$screenWidth: $width;
$screenHeight: $height;

$fontTitle: "Trenda";
$fontSerif: "Trenda Black It";
$sizeTitle: 50px;
$sizeSubTitle: 30px;
$sizeText: 20px;
$fontText: "Trenda";
$fontNav: "Trenda Black It";
$randomNumber: 300 + random(300);
