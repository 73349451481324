.map {
  z-index: 11;
  width: 100%;
  &__levels {
    height: 300px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .adesc & {
      display: none;
    }
  }
  &__img {
    .aguide & {

    }

  }
  &__level {
    @include absolute(2);
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }
    .bt_square {
      @include absolute(2);
    }
  }
  .map_aguide0 {

    [data-salle="0"] {
      top: 204px;
      left: 30px;
    }
    [data-salle="1"] {
      top: 97px;
      left: 30px;
    }
    [data-salle="2"] {
      top: 11px;
      left: 30px;
    }
  }
  .map_aguide1 {
    [data-salle="2"] {
      top: 204px;
      left: 30px;
    }
    [data-salle="3"] {
      top: 97px;
      left: 30px;
    }
    [data-salle="4"] {
      top: 11px;
      left: 30px;
    }
    [data-salle="0"] {
      top: 204px;
      left: 131px;
    }
    [data-salle="1"] {
      top: 204px;
      left: 216px;
    }
  }
  &__menu {
    .adesc & {
      padding-top: 40px;
    }
    width: 100%;
    &_level {

      @include absolute(1);
      opacity: 0;
      visibility: hidden;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
    ul {
      width: 100%;
      .bt {
        justify-content: flex-start;
        padding-left: 13px;
        height: 60px;
        margin-bottom: 18px;
        position: relative;
        .arrow {
          @include absolute(2);
          right: 20px;
          top: 0;
          bottom: 0;
          margin: auto;

        }
      }
    }
  }
}
