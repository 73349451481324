@font-face {
    font-family: 'Trenda Black It';
    src: url('../fonts/Trenda-BlackIt.woff2') format('woff2'),
        url('../fonts/Trenda-BlackIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda Bold It';
    src: url('../fonts/Trenda-BoldIt.woff2') format('woff2'),
        url('../fonts/Trenda-BoldIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda';
    src: url('../fonts/Trenda-Black.woff2') format('woff2'),
        url('../fonts/Trenda-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda ExtraLight It';
    src: url('../fonts/Trenda-ExtraLightIt.woff2') format('woff2'),
        url('../fonts/Trenda-ExtraLightIt.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda';
    src: url('../fonts/Trenda-Light.woff2') format('woff2'),
        url('../fonts/Trenda-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda';
    src: url('../fonts/Trenda-ExtraLight.woff2') format('woff2'),
        url('../fonts/Trenda-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda';
    src: url('../fonts/Trenda-Bold.woff2') format('woff2'),
        url('../fonts/Trenda-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda Heavy It';
    src: url('../fonts/Trenda-HeavyIt.woff2') format('woff2'),
        url('../fonts/Trenda-HeavyIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda Light It';
    src: url('../fonts/Trenda-LightIt.woff2') format('woff2'),
        url('../fonts/Trenda-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda';
    src: url('../fonts/Trenda-Heavy.woff2') format('woff2'),
        url('../fonts/Trenda-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda';
    src: url('../fonts/Trenda-Thin.woff2') format('woff2'),
        url('../fonts/Trenda-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda Semibold It';
    src: url('../fonts/Trenda-SemiboldIt.woff2') format('woff2'),
        url('../fonts/Trenda-SemiboldIt.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda';
    src: url('../fonts/Trenda-Regular.woff2') format('woff2'),
        url('../fonts/Trenda-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda It';
    src: url('../fonts/Trenda-RegularIt.woff2') format('woff2'),
        url('../fonts/Trenda-RegularIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda Thin It';
    src: url('../fonts/Trenda-ThinIt.woff2') format('woff2'),
        url('../fonts/Trenda-ThinIt.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Trenda';
    src: url('../fonts/Trenda-Semibold.woff2') format('woff2'),
        url('../fonts/Trenda-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
