.footer {
  height: 55px;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 500;
  background-color: #292929;
  transform: translateY(60px);
  transition: all 1s $out-circ;
  &.active {
    transform: translateY(0px);
  }
  p {
    color: #ffffff;
    font-family: 'Trenda';
    font-size: 23px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 55px;
    text-transform: uppercase;
    text-align: center;
  }
  .picto {
    @include absolute(1);
    top: 0px;
    bottom: 0px;
    margin: auto;
    width: 27px;
    height: 27px;
    &.prev {
      left: 10px;
    }
    &.home {
      right: 10px;
    }
  }

}
