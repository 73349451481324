.header {
  position: fixed;
  z-index: 100;
  height: 115px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(0);
  width: 100%;
  background-color: #b54b12;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: all 1s $out-circ 500ms;
  &__title {
    @include absolute(20);
    left: 0;
    right: 0;
    margin: auto;
    bottom: -10px;

    width: 90%;
    max-width: 640px;
    background-color: #292929;
    border-radius: 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 1s $out-circ 500ms;
    &.show {
      opacity: 1;
      visibility: visible;
    }
    p {
      font-family: 'Trenda';
      font-size: 15px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 34px;
      text-transform: uppercase;
      text-align: center;
      color:  $white;
    }
  }
  &__nav {
    opacity: 0;
    visibility: hidden;
    transition: all 1s $out-circ 500ms;
    &.show {
      opacity: 1;
      visibility: visible;
    }
    @include absolute(2);
    left: 0;
    right: 0;
    margin: auto;
    bottom: -10px;

    width: 90%;
    max-width: 640px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    .header_bt {
      width: 50%;
      height: 34px;

      p {
        font-family: 'Trenda';
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 34px;
        text-transform: uppercase;
        text-align: center;
        color:  #292929;
      }
      &.active {
        p {
          color: $white;
          font-weight: 700;
        }
        background-color: #292929;
      }
    }
    &_left{


      background-color: $white;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    &_right {

      background-color: $white;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  .p_splash & {
    transform: translateY(-120px);
  }
  .aguide & {
    background-color: #b54b12;
  }
  .adesc & {
    background-color: #741e00;
  }
}
