.salle {
  width: 100%;
  z-index: 14;
  margin-top: 70px;
  .bt {
    .picto {
      width: 28px;
      height: 28px;
    }
    justify-content: flex-start;
    padding-left: 13px;
    height: 60px;

    margin-bottom: 18px;
    position: relative;
    p {
      text-transform: none;
      font-weight: 700;
      line-height: normal;
      padding-right: 40px;
    }
    .arrow {
      @include absolute(2);
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;

    }
  }
}
