.bonus {
  width: 100%;
  margin-top: 0px;
  padding-top: 140px;
  text-align: center;
  // &__header {
  //   height: 253px;
  //   @include absolute(10);
  //   left: -40px;
  //   top: 115px;
  //   width: calc( 100% + 80px);
  //   background-image: url('../img/remparts.jpg');
  //   background-position: top center;
  //   background-size: cover;
  // }
  .bonus__bt {
    height: 27px;
    border-radius: 8px;
    max-width: 154px;
    margin: 0px auto;
    position: relative;

    p {
      font-size: 12px;
      line-height: 1;
    }
    .arrow {
      width: 7px;
      @include absolute(2);
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  &__list {
    width:calc( 100% + 40px);
    margin-left: -20px;
    margin-top: 20px;
    margin-bottom: 160px;
    &::before {
      @include pseudo-class(2);
      left: -1px;
      top: -145px;
      width: 15px;
      height: 145px;
      background-image: url('../img/fil.svg');
      background-repeat: no-repeat;



    }
    &::after {
      @include pseudo-class(2);
      left: -1px;
      bottom: -166px;
      width: 15px;
      height: 136px;
      background-image: url('../img/fil2.svg');
      background-repeat: no-repeat;



    }
    &_item {
      &::after {
        @include pseudo-class;
        width: 1px;
        background-color: #c34c00;
        height: calc(100% + 30px);
        left: 12px;
        top: 0;
      }

      margin-bottom: 30px;
      width: calc(100% - 30px);
      padding-left: 30px;
      img {
        width: 100%;
      }
      &::before {
        @include pseudo-class(2);
        left: 5px;
        top: -8px;
        width: 16px;
        height: 16px;
        border: 3px solid #c34c00;
        background-color: $white;
        border-radius: 10px;
      }
      p{
        padding-top: 10px;
        color: #676767;
        font-family: 'Trenda';
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        strong {

          font-weight: 700;
        }
      }
    }
  }
}
