/*------------------------------------*\
		Easing
\*------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  all: unset;
}

@font-face {
  font-family: "Trenda Black It";
  src: url("../fonts/Trenda-BlackIt.woff2") format("woff2"), url("../fonts/Trenda-BlackIt.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Trenda Bold It";
  src: url("../fonts/Trenda-BoldIt.woff2") format("woff2"), url("../fonts/Trenda-BoldIt.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Trenda";
  src: url("../fonts/Trenda-Black.woff2") format("woff2"), url("../fonts/Trenda-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trenda ExtraLight It";
  src: url("../fonts/Trenda-ExtraLightIt.woff2") format("woff2"), url("../fonts/Trenda-ExtraLightIt.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Trenda";
  src: url("../fonts/Trenda-Light.woff2") format("woff2"), url("../fonts/Trenda-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trenda";
  src: url("../fonts/Trenda-ExtraLight.woff2") format("woff2"), url("../fonts/Trenda-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trenda";
  src: url("../fonts/Trenda-Bold.woff2") format("woff2"), url("../fonts/Trenda-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trenda Heavy It";
  src: url("../fonts/Trenda-HeavyIt.woff2") format("woff2"), url("../fonts/Trenda-HeavyIt.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Trenda Light It";
  src: url("../fonts/Trenda-LightIt.woff2") format("woff2"), url("../fonts/Trenda-LightIt.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Trenda";
  src: url("../fonts/Trenda-Heavy.woff2") format("woff2"), url("../fonts/Trenda-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trenda";
  src: url("../fonts/Trenda-Thin.woff2") format("woff2"), url("../fonts/Trenda-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trenda Semibold It";
  src: url("../fonts/Trenda-SemiboldIt.woff2") format("woff2"), url("../fonts/Trenda-SemiboldIt.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Trenda";
  src: url("../fonts/Trenda-Regular.woff2") format("woff2"), url("../fonts/Trenda-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trenda It";
  src: url("../fonts/Trenda-RegularIt.woff2") format("woff2"), url("../fonts/Trenda-RegularIt.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Trenda Thin It";
  src: url("../fonts/Trenda-ThinIt.woff2") format("woff2"), url("../fonts/Trenda-ThinIt.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Trenda";
  src: url("../fonts/Trenda-Semibold.woff2") format("woff2"), url("../fonts/Trenda-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
  z-index: 100;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
  overflow: hidden;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom,
.glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.glightbox-container .ginner-container.desc-left,
.glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe,
.gslide video {
  outline: none !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-top .gslide-image img,
.desc-bottom .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img,
.desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  /* max-width: 160vmin; */
  margin: auto;
}

.gslide-video::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #f5eee9;
  text-align: left;
  overflow: auto;
  max-width: 100%;
  height: 100vh !important;
  width: 100vw !important;
}

.gslide-inline .ginlined-content {
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: auto;
}

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: flex !important;
  opacity: 1;
}

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  height: 100%;
}

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-top .gslide-media,
.desc-bottom .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left,
.gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom,
.gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/
.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  background: transparent;
  position: absolute;
  bottom: 15px;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: bold;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc string {
  color: #fff;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gprev,
.gnext,
.gclose {
  background: transparent;
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gprev svg,
.gnext svg,
.gclose svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gprev.disabled,
.gnext.disabled,
.gclose.disabled {
  opacity: 0.7;
}

.gprev .garrow,
.gnext .garrow,
.gclose .garrow {
  stroke: #f5eee9;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gnext,
.glightbox-closing .gprev,
.glightbox-closing .gclose {
  opacity: 0 !important;
}

/*Skin */
.glightbox-clean .gslide-description {
  position: absolute;
  bottom: 160px;
  right: 0px;
  transition: all 500ms;
  z-index: 10;
  background-color: #de5600;
  width: 520px;
  padding: 34px 30px;
}
.glightbox-clean .gslide-description p {
  color: #f5eee9;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  font-family: "Trenda";
  margin: 0;
}
.glightbox-clean .gslide-description p i {
  font-weight: 400;
  font-style: italic;
}

.glightbox-clean .gdesc-inner {
  padding: 0;
}

.glightbox-clean .gslide-title {
  pointer-events: none;
  font-size: 1em;
  font-weight: normal;
  font-family: arial;
  color: #000;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
  padding: 0;
}

.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
  fill: #f5eee9;
}

.glightbox-clean .gnext svg,
.glightbox-clean .gprev svg {
  height: 18px;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  right: 540px;
  width: 40px;
  height: 40px;
  background-color: #c8b69a;
  display: none;
}

.glightbox-clean .gnext {
  display: none;
  position: absolute;
  top: -100%;
  right: 450px;
  width: 40px;
  height: 40px;
  background-color: #c8b69a;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  bottom: 80px;
  right: 80px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

/*CSS Animations*/
.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .glightbox-container .ginner-container.desc-top .gslide-image,
.glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .gslide-image img {
    max-width: 100%;
  }

  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }

  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }

  .gslide-external {
    max-height: 100vh;
  }

  .gslide-description.description-left,
.gslide-description.description-right {
    max-width: 275px;
  }

  .glightbox-open {
    height: auto;
  }

  .crues .goverlay {
    background-color: #de5600;
  }
  .levees .goverlay {
    background-color: #707070;
  }

  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
  }

  .glightbox-clean .description-left .gdesc-inner,
.glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }

  .glightbox-clean .gslide-counter {
    background-color: #7c6954;
    font-size: 18px;
    font-weight: 700;
    font-family: "Trenda";
    color: #f5eee9;
    position: absolute;
    right: 490px;
    top: 878px;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .glightbox-clean .gprev {
    top: 878px;
  }

  .glightbox-clean .gnext {
    top: 878px;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
.bt {
  min-width: 210px;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.aguide .bt {
  background-color: #b54b12;
}
.adesc .bt {
  background-color: #741e00;
}
.bt p {
  color: #f5eee9;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 42px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}
.bt svg {
  padding-right: 10px;
}
.bt svg path {
  stroke: #f5eee9;
}

.bt_square {
  width: 40px;
  height: 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bt_square p {
  color: #292929;
  font-family: "Trenda";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  line-height: 18px;
}

.header {
  position: fixed;
  z-index: 100;
  height: 115px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(0);
  width: 100%;
  background-color: #b54b12;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
}
.header__title {
  position: absolute;
  z-index: 20;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  width: 90%;
  max-width: 640px;
  background-color: #292929;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
}
.header__title.show {
  opacity: 1;
  visibility: visible;
}
.header__title p {
  font-family: "Trenda";
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 34px;
  text-transform: uppercase;
  text-align: center;
  color: #f5eee9;
}
.header__nav {
  opacity: 0;
  visibility: hidden;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -10px;
  width: 90%;
  max-width: 640px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.header__nav.show {
  opacity: 1;
  visibility: visible;
}
.header__nav .header_bt {
  width: 50%;
  height: 34px;
}
.header__nav .header_bt p {
  font-family: "Trenda";
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 34px;
  text-transform: uppercase;
  text-align: center;
  color: #292929;
}
.header__nav .header_bt.active {
  background-color: #292929;
}
.header__nav .header_bt.active p {
  color: #f5eee9;
  font-weight: 700;
}
.header__nav_left {
  background-color: #f5eee9;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.header__nav_right {
  background-color: #f5eee9;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.p_splash .header {
  transform: translateY(-120px);
}
.aguide .header {
  background-color: #b54b12;
}
.adesc .header {
  background-color: #741e00;
}

.footer {
  height: 55px;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 500;
  background-color: #292929;
  transform: translateY(60px);
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.footer.active {
  transform: translateY(0px);
}
.footer p {
  color: #ffffff;
  font-family: "Trenda";
  font-size: 23px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 55px;
  text-transform: uppercase;
  text-align: center;
}
.footer .picto {
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  margin: auto;
  width: 27px;
  height: 27px;
}
.footer .picto.prev {
  left: 10px;
}
.footer .picto.home {
  right: 10px;
}

.menu {
  position: absolute;
  z-index: 9000;
  width: 104px;
  height: 104px;
  bottom: 40px;
  right: 40px;
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(252px);
  pointer-events: none;
}
.menu.active {
  transform: translateX(0px);
}
.menu__item {
  pointer-events: all;
  position: absolute;
  z-index: 20;
  width: 50px;
  height: 50px;
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu__item p {
  color: #f4ece4;
  font-family: "Trenda";
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}
.menu__item p::after {
  position: absolute;
  content: "";
  z-index: 1;
  bottom: -2px;
  left: 0;
  width: 110%;
  height: 2px;
  background-color: #f4ece4;
}
.menu [data-menu="0"] {
  z-index: 5;
  transform: translateY(52px);
  left: 52px;
  top: 0;
  background-color: #de5600;
}
.menu [data-menu="0"].active {
  transform: translateY(0px);
}
.menu [data-menu="1"] {
  left: 52px;
  top: 52px;
  background-color: #503829;
}
.menu [data-menu="2"] {
  left: 0px;
  top: 52px;
  background-color: #793f1a;
}

body {
  background: #f5eee9;
  color: #000;
  font-family: "Trenda";
}

html, body {
  height: 100%;
  overflow-x: hidden;
}

main {
  width: 100vw;
  height: 100%;
  position: relative;
  background: #f5eee9;
  z-index: 1;
  overflow-x: hidden;
}

.landscape {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  background-color: #f5eee9;
}

@media (orientation: landscape) {
  .landscape {
    display: block;
  }
}
* {
  background-repeat: no-repeat;
  user-select: none;
}
*::after {
  background-repeat: no-repeat;
}
*::before {
  background-repeat: no-repeat;
}
*:focus {
  outline: none;
}

sup {
  vertical-align: super;
  font-size: 14px;
  line-height: 1;
}

div, section {
  position: relative;
}

h1, h2, h3, h4 {
  font-family: "Trenda";
  letter-spacing: 1px;
}

p {
  font-family: "Trenda";
  line-height: 1.3;
}
p i {
  font-style: italic;
}
p b, p strong {
  font-weight: bold;
}

.page {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 0 40px;
  padding-top: 115px;
  min-height: 100%;
  top: 0;
  left: 0;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateX(100vw);
  background-color: #f5eee9;
}
.page.active {
  visibility: visible;
  opacity: 1;
}

.ultraHide {
  position: absolute;
  top: 1080px;
}

.hide {
  transform: translateX(100vw);
  opacity: 0;
  visibility: hidden;
}

.show {
  transform: translateX(0px);
  z-index: 90;
  opacity: 1;
  visibility: visible;
}

.absolute {
  position: absolute;
  z-index: 5;
}

.tuto {
  background-color: rgba(47, 47, 47, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

h1 .word, h2 .word, h3 .word, h4 .word {
  margin-right: 10px;
}

.splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
}
.splash__logo {
  width: 175px;
  margin-top: 30%;
}
.splash h2 {
  color: #b54b12;
  margin-top: 20px;
  font-family: "Trenda";
  font-size: 37px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}

.bonus {
  width: 100%;
  margin-top: 0px;
  padding-top: 140px;
  text-align: center;
}
.bonus .bonus__bt {
  height: 27px;
  border-radius: 8px;
  max-width: 154px;
  margin: 0px auto;
  position: relative;
}
.bonus .bonus__bt p {
  font-size: 12px;
  line-height: 1;
}
.bonus .bonus__bt .arrow {
  width: 7px;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.bonus__list {
  width: calc( 100% + 40px);
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: 160px;
}
.bonus__list::before {
  position: absolute;
  content: "";
  z-index: 2;
  left: -1px;
  top: -145px;
  width: 15px;
  height: 145px;
  background-image: url("../img/fil.svg");
  background-repeat: no-repeat;
}
.bonus__list::after {
  position: absolute;
  content: "";
  z-index: 2;
  left: -1px;
  bottom: -166px;
  width: 15px;
  height: 136px;
  background-image: url("../img/fil2.svg");
  background-repeat: no-repeat;
}
.bonus__list_item {
  margin-bottom: 30px;
  width: calc(100% - 30px);
  padding-left: 30px;
}
.bonus__list_item::after {
  position: absolute;
  content: "";
  z-index: 1;
  width: 1px;
  background-color: #c34c00;
  height: calc(100% + 30px);
  left: 12px;
  top: 0;
}
.bonus__list_item img {
  width: 100%;
}
.bonus__list_item::before {
  position: absolute;
  content: "";
  z-index: 2;
  left: 5px;
  top: -8px;
  width: 16px;
  height: 16px;
  border: 3px solid #c34c00;
  background-color: #f5eee9;
  border-radius: 10px;
}
.bonus__list_item p {
  padding-top: 10px;
  color: #676767;
  font-family: "Trenda";
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
.bonus__list_item p strong {
  font-weight: 700;
}

.home {
  z-index: 10;
}
.home .bt {
  height: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: 30px;
  flex-direction: column;
}
.home__header {
  width: 190px;
  margin: 40px auto;
  color: #292929;
  font-family: "Trenda";
  font-size: 25px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  text-align: center;
  position: relative;
}
.home__header::after {
  position: absolute;
  content: "";
  z-index: 1;
  right: -99%;
  bottom: 8px;
  width: 100%;
  height: 2px;
  background-color: #292929;
}
.home__header::before {
  position: absolute;
  content: "";
  z-index: 1;
  left: -99%;
  top: 14px;
  width: 100%;
  height: 2px;
  background-color: #292929;
}
.home .audioguide {
  background-color: #b54b12;
}
.home .audiodescription {
  background-color: #741e00;
}

.map {
  z-index: 11;
  width: 100%;
}
.map__levels {
  height: 300px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.adesc .map__levels {
  display: none;
}
.map__level {
  position: absolute;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}
.map__level.active {
  opacity: 1;
  visibility: visible;
}
.map__level .bt_square {
  position: absolute;
  z-index: 2;
}
.map .map_aguide0 [data-salle="0"] {
  top: 204px;
  left: 30px;
}
.map .map_aguide0 [data-salle="1"] {
  top: 97px;
  left: 30px;
}
.map .map_aguide0 [data-salle="2"] {
  top: 11px;
  left: 30px;
}
.map .map_aguide1 [data-salle="2"] {
  top: 204px;
  left: 30px;
}
.map .map_aguide1 [data-salle="3"] {
  top: 97px;
  left: 30px;
}
.map .map_aguide1 [data-salle="4"] {
  top: 11px;
  left: 30px;
}
.map .map_aguide1 [data-salle="0"] {
  top: 204px;
  left: 131px;
}
.map .map_aguide1 [data-salle="1"] {
  top: 204px;
  left: 216px;
}
.map__menu {
  width: 100%;
}
.adesc .map__menu {
  padding-top: 40px;
}
.map__menu_level {
  position: absolute;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.map__menu_level.active {
  opacity: 1;
  visibility: visible;
}
.map__menu ul {
  width: 100%;
}
.map__menu ul .bt {
  justify-content: flex-start;
  padding-left: 13px;
  height: 60px;
  margin-bottom: 18px;
  position: relative;
}
.map__menu ul .bt .arrow {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.salle {
  width: 100%;
  z-index: 14;
  margin-top: 70px;
}
.salle .bt {
  justify-content: flex-start;
  padding-left: 13px;
  height: 60px;
  margin-bottom: 18px;
  position: relative;
}
.salle .bt .picto {
  width: 28px;
  height: 28px;
}
.salle .bt p {
  text-transform: none;
  font-weight: 700;
  line-height: normal;
  padding-right: 40px;
}
.salle .bt .arrow {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.player {
  width: 100%;
  margin-top: 70px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.aguide .player {
  background: #f5eee9 url("../img/ondes.svg") center 42% no-repeat;
}
.adesc .player {
  background: #f5eee9;
}
.player__content {
  margin-top: -180px;
  width: 100%;
  height: 230px;
  text-align: center;
}
.player__content .ad {
  margin: 20px auto;
  display: none;
}
.adesc .player__content .ad {
  display: block;
}
.player h2 {
  font-family: "Trenda";
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23px;
  text-align: center;
  padding-top: 70px;
  text-transform: uppercase;
}
.aguide .player h2 {
  color: #c34c00;
}
.adesc .player h2 {
  color: #292929;
}
.player .controls {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}
.player .controls .toggleplaypause {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 36px;
}
.player .controls .toggleplaypause .picto {
  display: none;
}
.player .controls .toggleplaypause .picto.active {
  display: block;
}
.aguide .player .controls .toggleplaypause {
  background-color: #b54b12;
}
.adesc .player .controls .toggleplaypause {
  background-color: #741e00;
}
.player .timeline .bar {
  position: absolute;
  z-index: 1;
  background-color: #d37d47;
  height: 4px;
  width: 100%;
  z-index: 1;
}
.player .timeline .cursor {
  width: 11px;
  border-radius: 6px;
  height: 11px;
  position: absolute;
  z-index: 3;
  top: -4px;
}
.aguide .player .timeline .cursor {
  background-color: #b54b12;
}
.adesc .player .timeline .cursor {
  background-color: #741e00;
}
.player .timeline .progress {
  position: absolute;
  z-index: 2;
  height: 4px;
  width: 40%;
}
.aguide .player .timeline .progress {
  background-color: #b54b12;
}
.adesc .player .timeline .progress {
  background-color: #741e00;
}
.player .timeline .timespend {
  position: absolute;
  z-index: 5;
  top: 10px;
  left: 0;
}
.player .timeline .totaltime {
  position: absolute;
  z-index: 5;
  top: 10px;
  right: 0;
}