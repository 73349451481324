.menu {

  @include absolute(9000);
  width: 104px;
  height: 104px;
  bottom: 40px;
  right: 40px;
  transition: all 1s $out-cubic;
  transform: translateX(252px);
  pointer-events: none;
  &.active {
    transform: translateX(0px);
  }

  &__item {
    pointer-events: all;
    @include absolute(20);
    width: 50px;
    height: 50px;
    transition: all 500ms $out-cubic;
    @include flex-center;
    p {
      color: #f4ece4;
      font-family: "Trenda";
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      &::after {
        @include pseudo-class;
        bottom: -2px;
        left: 0;
        width: 110%;
        height: 2px;
        background-color: #f4ece4;
      }

    }
  }
  [data-menu="0"] {
    z-index: 5;
    transform: translateY(52px);
    &.active {
      transform: translateY(0px);
    }
    left: 52px;
    top: 0;
    background-color: #de5600;
  }
  [data-menu="1"] {
    left: 52px;
    top: 52px;
    background-color: #503829;
  }
  [data-menu="2"] {
    left: 0px;
    top: 52px;
    background-color: #793f1a;
  }

}
