.splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  &__logo {
    width: 175px;
    margin-top: 30%;
  }
  h2 {
    color: $orange;
    margin-top: 20px;
    font-family: 'Trenda';
    font-size: 37px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
  }
}
