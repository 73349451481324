.bt {
  min-width: 210px;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  .aguide & {
    background-color: #b54b12;
  }
  .adesc & {
    background-color: #741e00;
  }
  display: inline-flex;
  align-items: center;
  justify-content: center;
  p {
    color: $white;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 42px;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }
  svg {
    path {
      stroke:$white;
    }
    padding-right: 10px;
  }
}
.bt_square {
  width: 40px;
  height: 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  @include flex-center;
  p {
    color: #292929;
    font-family: 'Trenda';
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    line-height: 18px;
  }
}
