.player {
  width: 100%;
  margin-top: 70px;

  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .aguide &{
    background: $white url('../img/ondes.svg') center 42% no-repeat;
  }
  .adesc &{
      background: $white;
  }
  &__content {
    margin-top: -180px;
    width: 100%;
    height: 230px;
    text-align: center;
    .ad {
      margin: 20px auto;
      display: none;
      .adesc &{
        display: block;

      }
    }
  }
  h2 {
    .aguide &{
        color: #c34c00;
    }
    .adesc &{
        color: #292929;
    }
    font-family: 'Trenda';
    font-size: 20px;
    font-weight: 900;
    font-style: normal;
    letter-spacing: normal;
    line-height: 23px;
    text-align: center;
    padding-top: 70px;
    text-transform: uppercase;
  }
  .controls {
    @include flex-center;
    width: 100%;
    margin-bottom: 40px;

    .toggleplaypause {
      @include flex-center;
      width: 64px;
      height: 64px;
      border-radius: 36px;
      .picto {
        display: none;
        &.active {
          display: block;
        }
      }
      .aguide &{
        background-color: $orange;
      }
      .adesc &{
          background-color: $maroon;
      }
    }
  }
  .timeline {
    .bar {
      @include absolute(1);
      background-color: #d37d47;
      height: 4px;
      width: 100%;
      z-index: 1;
    }
    .cursor {
      .aguide &{
        background-color: $orange;
      }
      .adesc &{
          background-color: $maroon;
      }
      width: 11px;
      border-radius: 6px;
      height: 11px;
      @include absolute(3);
      top: -4px;
    }
    .progress {
      @include absolute(2);

      height: 4px;
      width: 40%;
      .aguide &{
        background-color: $orange;
      }
      .adesc &{
          background-color: $maroon;
      }
    }
    .timespend {
      @include absolute(5);
      top: 10px;
      left: 0;
    }
    .totaltime {
      @include absolute(5);
      top: 10px;
      right: 0;
    }
  }
}
