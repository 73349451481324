body {
  background: $white;
  color: $black;
  font-family: $fontText;


}
html, body {
  height: 100%;
  overflow-x: hidden;
}
main {
  width: 100vw;
  height: 100%;
  position: relative;
  background: $white;
  z-index: 1;
  overflow-x: hidden;
}
.landscape {
  display: none;
  width: 100%;
  height: 100%;
  @include absolute(999999);
  background-color: $white;
}
@media (orientation: landscape) {
  .landscape {
    display: block;
  }
}
* {
  background-repeat: no-repeat;
  &::after {
    background-repeat: no-repeat;
  }
  &::before {
    background-repeat: no-repeat;
  }
  user-select: none;
  &:focus {
      outline: none;
  }
}
sup {
  vertical-align: super;
  font-size: 14px;
  line-height: 1;
}
div, section {
  position: relative;
}
h1,h2,h3,h4 {
  font-family: $fontTitle;
  letter-spacing: 1px;
}
p {
  font-family: $fontText;
  line-height: 1.3;
  i {
    font-style: italic;
  }
  b,strong {
    font-weight: bold;
  }
}
* {

}

.page {
  @include hide;
  @include absolute;
  width: 100%;
  padding: 0 40px;
  padding-top: 115px;
  //height: 100vh;
  min-height: 100%;
  top:0;
  left:0;
  transition: all 500ms $out-circ;
  transform: translateX(100vw);
  background-color: $white;
  &.active {
    @include show;
  }
}
.ultraHide {
  position: absolute;
  top:1080px;
}
.hide {
  transform: translateX(100vw);
  opacity: 0;
  visibility: hidden;
}
.show {
  transform: translateX(0px);
  z-index: 90;
  opacity: 1;
  visibility: visible;
}
.absolute {
  @include absolute(5);
}
.tuto {
  background-color: rgba(#2f2f2f, 0.7);
  @include flex-center;
  z-index: 2000;
}
h1,h2,h3,h4 {
  &>* {
    // overflow: hidden;
  }
  // overflow: hidden;
  .word {
    margin-right: 10px;
  }
}
